import {Inject, Injectable, Optional, PLATFORM_ID} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {IAppConfig} from '../models/app-config.interface';
import {first} from 'rxjs/operators';
import {DOCUMENT, isPlatformServer} from '@angular/common';
import {REQUEST} from '@nguniversal/express-engine/tokens';

@Injectable({providedIn: 'root'})
export class AppConfig {
  static settings: IAppConfig;
  private readonly baseUrl: string = '';

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: Document,
    @Optional() @Inject(REQUEST) private request: any,
    private http: HttpClient,
  ) {
    if (isPlatformServer(platformId)) {
      const port = request.socket.localPort;

      // WHM/cPanel environment with Apache reverse proxy
      // this.baseUrl = this.request.protocol + '://' + this.request.hostname + ':' + port;

      // AWS environment with nginx reverse-proxy
      this.baseUrl = this.request.protocol + '://localhost:' + port;

    } else {
      this.baseUrl = this.document.location.origin;
    }
  }

  public load() {
    const jsonFile = `${this.baseUrl}/assets/config/config.${environment.name}.json`;
    return new Promise<void>((resolve, reject) => {
      this.http.get<IAppConfig>(jsonFile)
        .pipe(first())
        .subscribe((config: IAppConfig) => {
          AppConfig.settings = config;
          console.log(`env: ${environment.name}. Config loaded. api: ${AppConfig.settings.apiBaseUrl}`);
          resolve();
        }, (response) => {
          reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
        });
    });
  }
}
